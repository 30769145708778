import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { AdmDashboard } from './components/AdmDashboard';
import { AdmProducts } from "./components/AdmProducts";
import { AdmSuppliers } from "./components/AdmSuppliers"; 
import { AdmOperator } from "./components/AdmOperators";
import { AdmPorts } from "./components/AdmPorts";
import { AdmAssociation } from "./components/AdmAssociations";
import { AdmProdRefSys } from "./components/AdmProdRefSys";
import { AdmUnitBases } from "./components/AdmUnitBases";
import { AdmOpHeaders } from "./components/AdmOpHeaders";
import { AdmMyRequests} from "./components/AdmMyRequests";
import { AdmProdCategories } from "./components/AdmProdCategories";
import { AdmPeriod } from "./components/AdmPeriods";
import { AdmGenerate } from "./components/AdmGenerate";
import { AdmRegUsers } from "./components/AdmRegUsers";
import { AdmShellGrouping } from "./components/AdmShellGroupings";
import { AdmPricelist } from "./components/AdmPricelists";
import { AdmShellPortDiffs } from "./components/AdmShellPortDiffs";
import { AdmIPL } from "./components/AdmIPL";
//import { AdmShellDisc } from ".components/AdmShellDisc";
import { AdmOpProdRef } from "./components/AdmOpProdRef";
import { AdmVessels } from "./components/AdmVessels";
import { AdmServCatSys } from "./components/AdmServCatSys";
import { AdmOpServices } from "./components/AdmOpServices";
import { AdmOpSupplier } from "./components/AdmOpSupplier";
import { AdmOpPorts } from "./components/AdmOpPorts";
import { AdmItemConversions } from "./components/AdmItemConversions";
import { AdmUpload } from "./components/AdmUpload";
import { AdmImportData } from "./components/AdmImportData";
import { AdmPriceFind } from "./components/AdmPriceFind";
import { AdmPriceCompare } from "./components/AdmPriceCompare";
import { AdmReports } from "./components/AdmReports";
import { AdmHeaders } from "./components/AdmHeaders";
import { AdmProdTypes } from "./components/AdmProdTypes";
import { AdmOpPackaging } from "./components/AdmOpPackaging";
import { AdmProdSysCategories } from "./components/AdmProdSysCategories";
import { AdmProdSupCat } from "./components/AdmProdSupCat";
import { AdmVariance } from "./components/AdmVariance";
import { Home } from "./components/Home";
import { Navigate } from 'react-router-dom';


const AppRoutes2 = [
  {
    index: true,
    element: <Home />
    },
    { path: '/adm-ipl',
        requireAuth: true,
        element: <AdmIPL />
    },
    {
        path: '/adm-price-find',
        requireAuth: true,
        element: <AdmPriceFind />
    },
    {
        path: '/adm-price-compare',
        requireAuth: true,
        element: <AdmPriceCompare />
    },
    {
        path: '/adm-shell-diff',
        requireAuth: true,
        element: <AdmShellPortDiffs />
    },
    {
    path: '/admdashboard',
    requireAuth: true,
    element: <AdmDashboard />
    },
    {
        path: '/adm-products',
        requireAuth: true,
        element: <AdmProducts />
    },
    {
        path: '/adm-ports',
        requireAuth: true,
        element: <AdmPorts />
    },
    {
        path: '/adm-opports',
        requireAuth: true,
        element: <AdmOpPorts />
    },
    {
        path: '/adm-associations',
        requireAuth: true,
        element: <AdmAssociation />
    },

    {
       path: '/adm-suppliers',
       requireAuth: true,
        element: <AdmSuppliers />
    },
    {
        path: '/adm-opsuppliers',
        requireAuth: true,
        element: <AdmOpSupplier />
    },
    {
        path: '/adm-import',
        requireAuth: true,
        element: <AdmImportData />
    },
    {
        path: '/adm-prodcategories',
        requireAuth: true,
        element: <AdmProdCategories />
    },
    {
        path: '/adm-itemconversions',
        requireAuth: true,
        element: <AdmItemConversions />
    },
    {
        path: '/adm-upload',
        requireAuth: true,
        element: <AdmUpload />
    },
    {
        path: '/adm-prodrefsys',
        requireAuth: true,
        element: <AdmProdRefSys />
    },
    {
        path: '/adm-unitbases',
        requireAuth: true,
        element: <AdmUnitBases />
    },
    {
        path: '/adm-opheaders',
        requireAuth: true,
        element: <AdmOpHeaders />
    },
    {
        path: '/adm-myrequests',
        requireAuth: true,
        element: <AdmMyRequests />
    },
    {
        path: '/adm-opprodref',
        requireAuth: true,
        element: <AdmOpProdRef />
    },
    {
        path: '/adm-periods',
        requireAuth: true,
        element: <AdmPeriod />
    },
    {
        path: '/adm-generate',
        requireAuth: true,
        element: <AdmGenerate />
    },
    {
        path: '/adm-regusers',
        requireAuth: true,
        element: <AdmRegUsers />
    },
    {
        path: '/adm-vessels',
        requireAuth: true,
        element: <AdmVessels />
    },
    {
        path: '/adm-servcatsys',
        requireAuth: true,
        element: <AdmServCatSys />
    },
    {
        path: '/adm-opservices',
        requireAuth: true,
        element: <AdmOpServices />
    },
    {
        path: '/adm-shellgroupings',
        requireAuth: true,
        element: <AdmShellGrouping />
    },
    {
        path: '/adm-pricelists',
        requireAuth: true,
        element: <AdmPricelist />
    },
    {
        path: '/adm-operators',
        requireAuth: true,
        element: <AdmOperator />
    },
    {
        path: '/adm-reports',
        requireAuth: true,
        element: <AdmReports />
    },
    {
        path: '/adm-headers',
        requireAuth: true,
        element: <AdmHeaders />
    },
    {
        path: '/adm-prodtypes',
        requireAuth: true,
        element: <AdmProdTypes />
    },
    {
        path: '/adm-oppackaging',
        requireAuth: true,
        element: <AdmOpPackaging />
    },
    {
        path: '/adm-prodsyscategories',
        requireAuth: true,
        element: <AdmProdSysCategories />
    },
    {
        path: '/adm-prodsupcat',
        requireAuth: true,
        element: <AdmProdSupCat />
    },
    {
        path: '/adm-variance',
        requireAuth: true,
        element: <AdmVariance />
    },
    {
        path: "*",
        element: <Navigate to="/" replace />
    },
  ...ApiAuthorzationRoutes
];

export default AppRoutes2;
