import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import Autosuggest from 'react-autosuggest';
import './react-autosuggest.css';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 8;
var portList = [];

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : portList.filter(port =>
        port.name.toLowerCase().slice(0, inputLength) === inputValue
    );
};

const getSuggestionValue = suggestion => suggestion.name;

const renderSuggestion = suggestion => (
    <div>
        {suggestion.name}
    </div>
);


export class AdmPriceCompare extends Component {
    static displayName = AdmPriceCompare.name;

    //lists
    static allproducts = [];
    static suppliers = [];

    //input data
    static frmQuantity = "";
    static frmPack = "Bulk";
    static frmProduct = null;

    // input hidden
    static frmQuarter = "2024Q1";
    static frmPort1 = 0;
    static frmPort2 = 0;
    static frmPort3 = 0;
    static frmSupplier = 0;
    static frmDateStr = "";
    static frmDate = "";

    //header short
    static hdPortName = "";
    static hdSupplierName = "";
    static hdVesselName = "";

    // filter
    static frmProId = 0;

    //result data port1
    static resMethod1 = "";
    static resBasePrice1 = "";
    static resCategory1 = "";
    static resDiscount1 = "";
    static resPPD1 = "";
    static resUnit1 = "";
    static resPrice1 = "";
    static resRiceNum1 = 0.0;
    static resError1 = "";  

    //result data port2
    static resMethod2 = "";
    static resBasePrice2 = "";
    static resCategory2 = "";
    static resDiscount2 = "";
    static resPPD2 = "";
    static resUnit2 = "";
    static resPrice2 = "";
    static resPriceNum2 = 0.0;
    static resError2 = "";

    //result data port3
    static resMethod3 = "";
    static resBasePrice3 = "";
    static resCategory3 = "";
    static resDiscount3 = "";
    static resPPD3 = "";
    static resUnit3 = "";
    static resPrice3 = "";
    static resPriceNum3 = 0.0;
    static resError3 = "";


    //modal data
    static modId = 0;
    static modName = "";
    static modPort1 = 0;
    static modPort2 = 0;
    static modPort3 = 0;
    static modSupplier = 0;
    static modMember = 0;
    static modVessel = 0;
    static modDate = "";

    // handle to handle
    static isCalc = false;

    constructor(props) {
        super(props);
        this.state = {
            ports: [], suppliers: [], products: [], curSup: 0, operators: [],
            loading: true, val: "", value: '', value2: '', value3: '', suggestions: [], headers: [],
            modal: false, page: 0, dets: [], vessels:[], curVes: 0, periods: []
        };
        this.handleCalc = this.handleCalc.bind(this);
        this.handleModal = this.handleModal.bind(this);
        //Proforma header
        this.handleFilter = this.handleFilter.bind(this);
        this.handleMemVessels = this.handleMemVessels.bind(this);
        // handle CRUD
        this.handleDetAdd = this.handleDetAdd.bind(this);
        this.handleHeadDel = this.handleHeadDel.bind(this);
        this.handleHeaders = this.handleHeaders.bind(this);
        this.handleDetDelete = this.handleDetDelete.bind(this);
        // export
        this.handleExport = this.handleExport.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderDets(dets, that, hDetDelete) {
        console.log(dets);
        var val = AdmPriceCompare.frmProId;
        let myrec = that.headers.find(x => x.p3hId === parseInt(val));
        var port1id = myrec.p3hPort1;
        var port2id = myrec.p3hPort2;
        var port3id = myrec.p3hPort3;
        var port1 = that.ports.find(x => x.prtId === port1id).prtName;
        var port2 = that.ports.find(x => x.prtId === port2id).prtName;
        var port3 = that.ports.find(x => x.prtId === port3id).prtName;

        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Quantity</th>
                            <th>Pack.</th>
                            <th>Product</th>
                            <th>Unit</th>
                            <th>{port1}</th>
                            <th>{port2}</th>
                            <th>{port3}</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dets.map((det) =>
                            <tr key={det.p3dtId}>
                                <td>{det.p3dQuantity}</td>
                                <td>{det.p3dPackaging}</td>
                                <td>{det.p3dProductName}</td>
                                <td>{det.p3dUnit}</td>
                                <td>{det.p3dPort1Price}</td>
                                <td>{det.p3dPort2Price}</td>
                                <td>{det.p3dPort3Price}</td>

                                <td>
                                    <Button color="danger"
                                        onClick={hDetDelete} value={det.pdetId} >
                                        Delete 
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }


    async handleCalc(event) {
        AdmPriceCompare.isCalc = true;
        var ret = await this.doCalc();
        // if (!AdmPriceCompare.isCalc) return;
        this.setState({ loading: false });
    }

    async handleHeaders(event) {
        let val = event.target.value;
        AdmPriceCompare.frmProId = val;
        // set frmPort, frmSupplier, frmDate & curSup
        var myrec = this.state.headers.find(x => x.p3hId === parseInt(val));
        AdmPriceCompare.frmPort1 = myrec.p3hPort1;
        AdmPriceCompare.frmPort2 = myrec.p3hPort2;
        AdmPriceCompare.frmPort3 = myrec.p3hPort3;
        AdmPriceCompare.frmSupplier = myrec.p3hSupplier;
        AdmPriceCompare.frmDate = myrec.p3hDate;
        AdmPriceCompare.hdPortName = this.state.ports.find(x => x.prtId === myrec.p3hPort).prtName;
        AdmPriceCompare.hdSupplierName = this.state.suppliers.find(x => x.supId === myrec.p3hSupplier).supName;
        AdmPriceCompare.hdVesselName = this.state.vessels.find(x => x.vesId === myrec.p3hVessel).vesCurrentName;
        this.setState({ curSup: myrec.p3hSupplier });
    }

    async handleExport(event) {
        // get list to export
        if (this.state.headers.length === 0) { alert("No List to Export"); return; }
        if (AdmPriceCompare.frmProId == 0) AdmPriceCompare.frmProId = this.state.headers[0].p3hId;
        // send header id to server
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/proListExport/' + AdmPriceCompare.frmProId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        if (response.ok) {
            // load file to client
            var res = "upload/proforma" + AdmPriceCompare.frmProId + ".xlsx";
            window.open(res, '_blank', 'noreferrer');
            await sleep(1000);
            /*
            alert("Make sure that your file is uploaded\n" +
                "and Click OK to remove it from the server\n" +
                  "for security reasons");
            */
        }
        else alert("failed to export this list");
    }

    async doCalc() {
        if (AdmPriceCompare.frmQuantity == null) AdmPriceCompare.frmQuantity = 0;

        if (AdmPriceCompare.frmPort1 == null) AdmPriceCompare.frmPort1 = 0;
        if (AdmPriceCompare.frmPort2 == null) AdmPriceCompare.frmPort2 = 0;
        if (AdmPriceCompare.frmPort3 == null) AdmPriceCompare.frmPort3 = 0;
     
        if (AdmPriceCompare.frmProduct == null) {
            alert("Please select a Product");
            AdmPriceCompare.isCalc = false;
            return;
        }
        
        if ((AdmPriceCompare.frmQuantity == "") || (AdmPriceCompare.frmQuantity == 0)) {
            alert("Please insert a Quantity");
            AdmPriceCompare.isCalc = false;
            return;
        }

        // get quarter

        var myDate = Date.parse(AdmPriceCompare.frmDate);
        var formDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
            .format(myDate);
       
        var period = this.state.periods.find
            (x => ((Date.parse(x.perStart) <= myDate) && (Date.parse(x.perEnd) >= myDate)
                   && (x.perSupplier == AdmPriceCompare.frmSupplier)     )
        );

        if (period == null) {
            alert("No prices for the selected date: " + formDate);
            AdmPriceCompare.isCalc = false;
            return;
        }
        AdmPriceCompare.frmQuarter = period.perName;


        // price port1
        if (AdmPriceCompare.frmPort1 != 0)
        {
            const token = await authService.getAccessToken();
            var response = await fetch('mar1/getPrice', {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        Supplier: AdmPriceCompare.frmSupplier,
                        Quantity: AdmPriceCompare.frmQuantity,
                        Pack: AdmPriceCompare.frmPack,
                        Product: AdmPriceCompare.frmProduct,
                        Port: AdmPriceCompare.frmPort1,
                        Period: AdmPriceCompare.frmQuarter
                    }
                )
            });
            if (!response.ok) {
                alert("Bad Input (1). Please correct");
                AdmPriceCompare.isCalc = false;
                return;
            }

            var res = await response.json();
            AdmPriceCompare.resMethod1 = res.resMethod;
            AdmPriceCompare.resBasePrice1 = res.resBasePrice;
            AdmPriceCompare.resCategory1 = res.resCategory;
            AdmPriceCompare.resDiscount1 = res.resDiscount;
            AdmPriceCompare.resPPD1 = res.resPPD;
            AdmPriceCompare.resUnit1 = res.resUnit;
            AdmPriceCompare.resPrice1 = res.resPrice;
            AdmPriceCompare.resPriceNum1 = res.resPriceNum;
            if (res.resError != "ok") {
                alert(res.resError);
                AdmPriceCompare.isCalc = false;
            }
        }

        //price port2
        if (AdmPriceCompare.frmPort2 != 0) {
            const token = await authService.getAccessToken();
            var response2 = await fetch('mar1/getPrice', {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        Supplier: AdmPriceCompare.frmSupplier,
                        Quantity: AdmPriceCompare.frmQuantity,
                        Pack: AdmPriceCompare.frmPack,
                        Product: AdmPriceCompare.frmProduct,
                        Port: AdmPriceCompare.frmPort2,
                        Period: AdmPriceCompare.frmQuarter
                    }
                )
            });
            if (!response2.ok) {
                alert("Bad Input (2). Please correct");
                AdmPriceCompare.isCalc = false;
                return;
            }

            var res2 = await response2.json();
            AdmPriceCompare.resMethod2 = res.resMethod;
            AdmPriceCompare.resBasePrice2 = res.resBasePrice;
            AdmPriceCompare.resCategory2 = res.resCategory;
            AdmPriceCompare.resDiscount2 = res.resDiscount;
            AdmPriceCompare.resPPD2 = res.resPPD;
            AdmPriceCompare.resUnit2 = res.resUnit;
            AdmPriceCompare.resPrice2 = res.resPrice;
            AdmPriceCompare.resPriceNum2 = res.resPriceNum;
            if (res2.resError != "ok") {
                alert(res2.resError);
                AdmPriceCompare.isCalc = false;
            }
        }


        //price port3
        if (AdmPriceCompare.frmPort3 != 0) {
            const token = await authService.getAccessToken();
            var response3 = await fetch('mar1/getPrice', {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        Supplier: AdmPriceCompare.frmSupplier,
                        Quantity: AdmPriceCompare.frmQuantity,
                        Pack: AdmPriceCompare.frmPack,
                        Product: AdmPriceCompare.frmProduct,
                        Port: AdmPriceCompare.frmPort3,
                        Period: AdmPriceCompare.frmQuarter
                    }
                )
            });

            if (!response3.ok) {
                alert("Bad Input (3). Please correct");
                AdmPriceCompare.isCalc = false;
                return;
            }

            var res3 = await response3.json();
            AdmPriceCompare.resMethod3 = res.resMethod;
            AdmPriceCompare.resBasePrice3 = res.resBasePrice;
            AdmPriceCompare.resCategory3 = res.resCategory;
            AdmPriceCompare.resDiscount3 = res.resDiscount;
            AdmPriceCompare.resPPD3 = res.resPPD;
            AdmPriceCompare.resUnit3 = res.resUnit;
            AdmPriceCompare.resPrice3 = res.resPrice;
            AdmPriceCompare.resPriceNum3 = res.resPriceNum;
            if (res3.resError != "ok") {
                alert(res3.resError);
                AdmPriceCompare.isCalc = false;
            }
        }
    }

    handleFilter(event) {
        let val = event.target.value;
        AdmPriceCompare.modSupplier = val;
        this.setState({ curSup: val });
    }

    handleMemVessels(event) {
        let val = event.target.value;
        AdmPriceCompare.modMember = val;
        this.setState({ curVes: val });
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmPriceCompare.modId = 0;
                AdmPriceCompare.modName = "";
                AdmPriceCompare.modPort1 = 0;
                AdmPriceCompare.modPort2 = 0;
                AdmPriceCompare.modPort3 = 0;
                this.onChange1(event, { newValue: "" });
                this.onChange2(event, { newValue: "" });
                AdmPriceCompare.modSupplier = 0;
                AdmPriceCompare.modMember = 0;              
                AdmPriceCompare.modVessel = 0;
                AdmPriceCompare.modDate = "";
                this.setState({ modal: true, curVes: 0 });
            }
            else {
                if (this.state.headers.length === 0) {
                    alert("No Proformas found");
                    return;
                }
                if (AdmPriceCompare.frmProId == 0) AdmPriceCompare.frmProId = this.state.headers[0].p3hId;
                val = AdmPriceCompare.frmProId;
                let myrec = this.state.headers.find(x => x.p3hId === parseInt(val));
                AdmPriceCompare.modId = myrec.p3hId;
                AdmPriceCompare.modName = myrec.p3hName;
                AdmPriceCompare.modPort = myrec.p3hPort;
                const portName = this.state.ports.find(x => x.prtId === myrec.p3hPort).prtName;
                this.onChange1(event, { newValue: portName });
                AdmPriceCompare.modSupplier = myrec.p3hSupplier;
                AdmPriceCompare.modMember = myrec.p3hMember;
                AdmPriceCompare.modVessel = myrec.p3hVessel;
                AdmPriceCompare.modDate = myrec.p3hDate;
                //const dd = date.slice(0, 2), dm = date.slice(3, 5), dy = date.slice(6, 10);
                //AdmPriceCompare.modDate = dy+"-" + dm + "-" + dd;
                this.setState({ modal: true, curVes: myrec.p3hMember });
            }
        }
        else {
            if (val !== "-1") {
                if (val !== "0") {
                    
                    if (window.confirm("This may delete other Suppliers items \n\r" +
                        "and may affect prices for all lines \n\r " +
                        "Are you sure? " ) === false) {
                        return;
                    }        
                    AdmPriceCompare.frmProId = val;
                    const res = await AdmPriceCompare.headUpdate();
                    if (res.result !== "ok") {
                        alert("Error: " + res.result);
                        this.setState({ modal: false });
                        return;
                    }

                    // header updated
                    // update dets

                    const token = await authService.getAccessToken();
                    var response = await fetch('mar1/getprice/' + AdmPriceCompare.frmProId, {
                        method: 'PUT',
                        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
                    });

                    // alert("ProForma updated successfully");
                    // await sleep(500);
                    this.populateData();
                    this.setState({ modal: false });
                    return;
                    // let the database catch up
                    //    const token = await authService.getAccessToken();
                    //    const response = await fetch('mar1/proListHeads', {
                    //        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    //    });
                    //    const dbheaders = await response.json();
                    //    this.setState({ headers: dbheaders, modal: false });
                    //    return;                        

                } else {
                    const res = await AdmPriceCompare.headAdd(); 
                    if (res.result !== "ok") {
                        alert("Error: " + res.result);
                        return;
                    } else {
                        alert("Proforma created successfully");
                        await sleep(1000);
                        // let the database catch up
                        // this.populateData();
                         const token = await authService.getAccessToken();
                         const response = await fetch('mar1/proListHeads', {
                             headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                         });
                         const dbheaders = await response.json();
                         AdmPriceCompare.frmProId = res.p3hId;
                         this.setState({ headers: dbheaders, modal: false });
                        return;
                    }

                }
            }
            else {
                this.setState({ modal: false });
            }
        }
    }

    static async headAdd() {
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/proListHead3', {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    P3hUser: null,
                    P3hName: AdmPriceCompare.modName,
                    P3hMember: AdmPriceCompare.modMember,
                    p3hSupplier: AdmPriceCompare.modSupplier,
                    p3hPort1: AdmPriceCompare.modPort1,
                    p3hPort2: AdmPriceCompare.modPort2,
                    p3hPort3: AdmPriceCompare.modPort3,
                    P3hVessel: AdmPriceCompare.modVessel,
                    P3hDate: AdmPriceCompare.modDate
                }
            )
        });
        var res = await response.json();
        if (response.status === 201) // created
            return {result: "ok"};
        else {
            return {result: res}
        }
    }

    static async headUpdate() {
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/proListHeads/' + AdmPriceCompare.frmProId, { 
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    P3hId: AdmPriceCompare.frmProId,
                    P3hName: AdmPriceCompare.modName,
                    P3hMember: AdmPriceCompare.modMember,
                    P3hSupplier: AdmPriceCompare.modSupplier,
                    P3hPort: AdmPriceCompare.modPort,
                    P3hVessel: AdmPriceCompare.modVessel,
                    P3hDate: AdmPriceCompare.modDate
                }
            )
        });
        if (response.status == 204) // nocontent cannot be read by json
            return {result: "ok"};
        else {
            return {result: "error " + response.status}
        }
    }

    async handleDetAdd(event) {
        if (this.state.headers.length === 0) {
            alert("Please create a List"); return;
        }
        if (AdmPriceCompare.frmProId == 0) AdmPriceCompare.frmProId = this.state.headers[0].p3hId;
        AdmPriceCompare.isCalc = true;
        var ret = await this.doCalc();
        if (!AdmPriceCompare.isCalc) return;


        var prod = this.state.products.find(x => x.prdId == AdmPriceCompare.frmProduct);
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/proListDet3', {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    P3dHead: AdmPriceCompare.frmProId,
                    P3dProduct : AdmPriceCompare.frmProduct,
                    P3dQuantity: parseFloat(AdmPriceCompare.frmQuantity),
                    P3dPackaging: AdmPriceCompare.frmPack,
                    P3dPeriod: AdmPriceCompare.frmQuarter,
                    P3dCategory: AdmPriceCompare.resCategory1,
                    P3Unit: AdmPriceCompare.resUnit1,
                    P3dProductName: prod.prdRefCode,
                    P3dPort1Price: AdmPriceCompare.resPrice1,
                    P3dPriceNum1: AdmPriceCompare.resPriceNum1,
                    P3dPort22Price: AdmPriceCompare.resPrice2,
                    P3dPriceNum2: AdmPriceCompare.resPriceNum2,
                    P3dPort3Price: AdmPriceCompare.resPrice3,
                    P3dPriceNum3: AdmPriceCompare.resPriceNum3,
                    PdetDate: AdmPriceCompare.frmDate,
                    PdetUser: null,
                    PdetProductName: prod.prdRefCode
                }
            )
        });
        const response6 = await fetch('mar1/proListDets', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbdets = await response6.json();
        this.setState({ dets: dbdets });
    }

    async handleHeadDel(event) {
        if (this.state.headers.length === 0) {
            alert("No List to Delete"); return;
        }
        if (window.confirm("Are you sure you want to delete this item?") === false) {
            return;
        }
        if (AdmPriceCompare.frmProId == 0) AdmPriceCompare.frmProId = this.state.headers[0].p3hId;
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/ProListHeads/' + AdmPriceCompare.frmProId, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const response4 = await fetch('mar1/proListHeads', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbheaders = await response4.json();
        const response6 = await fetch('mar1/proListDets', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbdets = await response6.json();

        if (dbheaders.length > 0)
            AdmPriceCompare.frmProId = dbheaders[0].p3hId;
        else {
            AdmPriceCompare.frmProId = 0;
            AdmPriceCompare.frmDate = "";
            AdmPriceCompare.hdPortName = "";
            AdmPriceCompare.hdSupplierName = "";
            AdmPriceCompare.hdVesselName = "";
        }
        this.setState({ headers: dbheaders, dets:dbdets });
    }

    async handleDetDelete(event) {
        let val = event.target.value;
        if (window.confirm("Are you sure you want to delete this item?") === false) {
            return;
        }
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/ProListDets/' + val, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const response6 = await fetch('mar1/proListDets', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbdets = await response6.json();
        this.setState({ dets: dbdets });
    }

    onChange1 = (event, { newValue }) => {
        // seek newvalue in portList
        console.log("looking for 1: " + newValue);
        var myport = this.state.ports.find(function (port) {
            return port.prtName.toLowerCase() == newValue.toLowerCase();
        });
        if (myport != null) {
            AdmPriceCompare.modPort1 = myport.prtId;
        }
        else {
            AdmPriceCompare.modPort1 = 0;
        }
        this.setState({
            value: newValue,
        });
    }

    onChange2 = (event, { newValue }) => {
        // seek newvalue in portList
        console.log("looking for 2: " + newValue);
        var myport = this.state.ports.find(function (port) {
            return port.prtName.toLowerCase() == newValue.toLowerCase();
        });
        if (myport != null) {
            AdmPriceCompare.modPort2 = myport.prtId;
        }
        else {
            AdmPriceCompare.modPort2 = 0;
        }
        this.setState({
            value2: newValue,
        });
    }

    onChange3 = (event, { newValue }) => {
        // seek newvalue in portList
        console.log("looking for 2: " + newValue);
        var myport = this.state.ports.find(function (port) {
            return port.prtName.toLowerCase() == newValue.toLowerCase();
        });
        if (myport != null) {
            AdmPriceCompare.modPort3 = myport.prtId;
        }
        else {
            AdmPriceCompare.modPort3 = 0;
        }
        this.setState({
            value3: newValue,
        });
    }

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value, value2, value3, suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps =
        {
            placeholder: 'Type a port',
            value: value,
            onChange: this.onChange1
        };
        
        const inputProps2 =
        {
            placeholder: 'Type a port',
            value: value2,
            onChange: this.onChange2
        };

        const inputProps3 =
        {
            placeholder: 'Type a port',
            value: value3,
            onChange: this.onChange3
        };
       
        var ports = [];
        var products = [];
        var memVessels = [];
        var curSup = this.state.curSup;
        var curVes = this.state.curVes;
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "box-shadow": "none" };
        var lines = [];
        var total = 0;
        
        if (!this.state.loading) {
            ports = this.state.ports;
            AdmPriceCompare.suppliers = this.state.suppliers;
            AdmPriceCompare.allproducts = this.state.products;
            products = AdmPriceCompare.allproducts.filter(function (product) {
                return product.prdSupplier == curSup;
            });
            memVessels = this.state.vessels.filter(function (vessel) {
                return vessel.vesOperator == curVes;
            });
            if (AdmPriceCompare.frmProduct == null)
                AdmPriceCompare.frmProduct = products[0].prdId;
            if (AdmPriceCompare.frmSupplier == null)
                AdmPriceCompare.frmSupplier = AdmPriceCompare.suppliers[0].supId;
            portList = [];
            ports.forEach((port) => {
                portList.push({ name: port.prtName, id: port.ptrId });
            });
            // filter
            lines = this.state.dets;
            if ((this.state.headers.length > 0) && (AdmPriceCompare.frmProId == 0))
                AdmPriceCompare.frmProId = this.state.headers[0].p3hId;
            lines = lines.filter(function (line) {
                return line.p3dHead == AdmPriceCompare.frmProId;
            });
            for (let i = 0; i < lines.length; i++) {
                total += lines[i].pdetPriceNum;
            }
            total = total.toFixed(2);
            //pages
            const sorted = lines;
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                lines = sorted.slice((page) * pageSize, (page + 1) * pageSize);
            } else {
                lines = sorted;
            }
        }

        const contents1 = this.state.loading
            ? <p><em>...loading </em></p>
            : AdmPriceCompare.renderDets(lines, this.state, this.handleDetDelete);


        if ((!this.state.loading) && (pages > 1)) {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc + 1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                <PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");

        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-2">
                            <h3 id="tabelLabel" > Compare Ports
                            </h3>
                        </div>
                        <div class="col-lg-4">
                            <select style={{
                                "width": "100%", "height": "37px",
                                "margin-top": "7px", "background": "aliceblue" }}
                                onChange={this.handleHeaders}
                                defaultValue={AdmPriceCompare.frmProId}
                                id="frmProId">
                                {this.state.headers.map((head) =>
                                    <option value={head.p3hId}
                                    >{head.p3hName}</option>)}
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <Button color="info" onClick={this.handleModal} value={-1}
                                style={{ "width": "100%", "margin-top": "7px" }}>
                                New Header
                            </Button>
                        </div>
                        <div class="col-lg-2">
                            <Button color="info" onClick={this.handleModal} value={AdmPriceCompare.frmProId}
                                style={{ "width": "100%", "margin-top": "7px" }}>
                                Edit Header
                            </Button>
                        </div>
                        <div class="col-lg-2">
                            <Button color="danger" onClick={this.handleHeadDel}
                                style={{ "width": "100%", "margin-top": "7px" }}>
                                Delete Header
                            </Button>
                        </div>
                    </div>

                    <div class="row" style={{ "background": "beige", "margin-top": "10px" }}>
                        <div class="col-lg-2">
                            Supplier: <b>{AdmPriceCompare.hdSupplierName}</b>
                        </div>
                        <div class="col-lg-4">
                        </div>
                        <div class="col-lg-2">
                            Date: <b>{AdmPriceCompare.frmDate}</b>
                        </div>
                        <div class="col-lg-4">
                            Vessel: <b>{AdmPriceCompare.hdVesselName.substring(0,
                                Math.min(AdmPriceCompare.hdVesselName.length, 26))}</b>
                        </div>

                    </div>

                    <div class="row" style={{ "margin-top": "5px" }}>
                        <div class="col-lg-4">
                            <Label><b>Product</b></Label>
                            <select style={{ "width": "100%", "height": "35px", "background-color": "#e9ecef" }}
                                onChange={(e) => AdmPriceCompare.frmProduct = e.target.value}>
                                id = "frmproduct"
                                {products.map((product) =>
                                    <option value={product.prdId}
                                    >{product.prdRefCode}</option>)}
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <Label><b>Packaging</b></Label>
                            <select style={{ "width": "100%", "height": "35px", "background-color": "#e9ecef" }}
                                onChange={(e) => AdmPriceCompare.frmPack = e.target.value}>
                                <option value="Bulk">Bulk</option>
                                <option value="Ibc">IBC</option>
                                <option value="Drum">Drum</option>
                                <option value="Pail">Pail</option>
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <Label><b>Quantity</b></Label>
                            <Input onChange={(e) => AdmPriceCompare.frmQuantity = e.target.value}
                                id="frmQuantity"
                                type="text" defaultValue = "0"
                                style={{ "background-color": "#e9ecef", "text-align":"right" }}
                            />
                        </div>
                        <div class="col-lg-2">
                            <Button color="success" onClick={this.handleDetAdd }
                                style={{ "width": "100%", "margin-top": "30px" }}>
                                Add Line
                            </Button>
                        </div>
                        <div class="col-lg-2">
                            <Button color="warning" onClick={this.handleExport} value={AdmPriceCompare.frmProId}
                                style={{ "width": "100%", "margin-top": "30px" }}>
                                Excel Export
                            </Button>
                        </div>
                    </div>
                </div>
                <br />
                {contents1}

                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                        </div>
                        <div class="col-lg-2">
                            Lines: <b>{lines.length}</b>
                        </div>
                        <div class="col-lg-4">
                            Total: $ <b>{total}</b>
                        </div>
                    </div>
                </div>


                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages - 1, page + 1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })} />  </PaginationItem>
                </Pagination>

                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Proforma</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modName" style={{ "padding-top": "15px" }}>
                            <b>Name</b>
                            </Label>
                            <Input onChange={(e) => AdmPriceCompare.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={AdmPriceCompare.modName}
                            />
                            <Label style={{ "padding-top": "15px" }}>
                                <b>Port 1</b></Label>
                            <Autosuggest
                                id="modPort1"
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue} 
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps}
                            />

                            <Label style={{ "padding-top": "15px" }}>
                                <b>Port 2</b></Label>
                            <Autosuggest
                                id="modPort2"
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps2}
                            />

                            <Label style={{ "padding-top": "15px" }}>
                                <b>Port 3</b></Label>
                            <Autosuggest
                                id="modPort3"
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps3}
                            />

                            <Label style={{ "padding-top": "15px" }}>
                                <b>Supplier</b>
                            </Label>
                            <select style={{ "width": "100%", "height": "35px" }}
                                onChange={this.handleFilter}
                                id = "modSupplier"
                                defaultValue={AdmPriceCompare.modSupplier}>
                                <option value={0}></option>
                                {AdmPriceCompare.suppliers.map((supplier) =>
                                    <option value={supplier.supId}
                                    >{supplier.supName}</option>)}
                            </select>
                            <Label for="modMember" style={{ "padding-top": "15px" }}>
                                <b>Member</b>
                            </Label>
                            <select style={{ "width": "100%", "height": "35px" }}
                                onChange={this.handleMemVessels}
                                id="modMember"
                                defaultValue={AdmPriceCompare.modMember}>
                                <option value={0}></option>
                                {this.state.operators.map((operator) =>
                                    <option value={operator.oprId}>{operator.oprName}</option>)}
                            </select>
                            <Label for="modVessel" style={{ "padding-top": "15px" }}>
                                <b>Vessel</b>
                            </Label>
                            <select style={{ "width": "100%", "height": "35px" }}
                                onChange={(e) => AdmPriceCompare.modVessel = e.target.value}
                                id="modVessel"
                                defaultValue={AdmPriceCompare.modVessel}>
                                <option value={0}></option>
                                {memVessels.map((vessel) =>
                                    <option value={vessel.vesId}>{vessel.vesCurrentName}</option>)}
                            </select>
                            <Label for="modDate" style={{ "padding-top": "15px" }}>
                                <b>Date</b>
                            </Label>
                            <Input onChange={(e) => AdmPriceCompare.modDate = e.target.value}
                                id="DateFrom" type="date"
                                dateformat= 'DD/M/YYYY'
                                style={{
                                    "height": "35px",
                                    "background-color": "#e9ecef"
                                }} defaultValue={AdmPriceCompare.modDate}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmPriceCompare.modId}>
                            {(AdmPriceCompare.modId === 0) ? "Add New" : "Apply Changes"}
                            </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}
                            style={{ "width" : "100px"} }
                        >
                            Exit
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async dataGet() {
        console.log("getdata");
        const token = await authService.getAccessToken();

        const response1 = await fetch('mar1/suppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsuppliers = await response1.json();

        const response2 = await fetch('mar1/ports', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbports = await response2.json();

        const response3 = await fetch('mar1/products', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbproducts = await response3.json();

        const response4 = await fetch('mar1/proListHead3', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
          });
        const dbheaders = await response4.json();

         const response5 = await fetch('mar1/operators', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dboperators = await response5.json();

        const response6 = await fetch('mar1/proListDet3', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbdets = await response6.json();

        const response7 = await fetch('mar1/vessels', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbvessels = await response7.json();

        const response8 = await fetch('mar1/periods', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbperiods = await response8.json();


        return {
            ports: dbports, suppliers: dbsuppliers, products: dbproducts,
            headers: dbheaders, operators: dboperators, dets: dbdets, vessels: dbvessels,
            periods: dbperiods
        };
    }

    async populateData() {
        const data = await AdmPriceCompare.dataGet();
        const dbsuppliers = data.suppliers;
        const dbports = data.ports;
        const dbproducts = data.products;
        const dbheaders = data.headers;
        const dbdets = data.dets;
        const dboperators = data.operators;
        const dbvessels = data.vessels;
        const dbperiods = data.periods;
        var vcurSup = 1;
        if (dbheaders.length > 0) {
            AdmPriceCompare.frmProId = dbheaders[0].p3hId;
            AdmPriceCompare.frmSupplier = dbheaders[0].p3hSupplier;
            AdmPriceCompare.frmDate = dbheaders[0].p3hDate;
            AdmPriceCompare.hdSupplierName = dbsuppliers.find(x => x.supId === dbheaders[0].p3hSupplier).supName;
            AdmPriceCompare.hdVesselName = dbvessels.find(x => x.vesId === dbheaders[0].p3hVessel).vesCurrentName;
            vcurSup = dbheaders[0].p3hSupplier
            AdmPriceCompare.frmPort1 = dbheaders[0].p3hPort1;
            AdmPriceCompare.frmPort2 = dbheaders[0].p3hPort2;
            AdmPriceCompare.frmPort3 = dbheaders[0].p3hPort3;
        }
       this.setState({
            ports: dbports, suppliers: dbsuppliers, products: dbproducts, curSup: vcurSup, operators: dboperators,
            loading: false, headers: dbheaders, dets: dbdets, vessels: dbvessels, curVes: 0, periods: dbperiods
        });
    }
}


