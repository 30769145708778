import React, { Component } from 'react';
import {
    Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink
    , UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem  
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu2.css';

export class NavMenu2 extends Component {
  static displayName = NavMenu2.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
        <header style={{background:"beige"}} >
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
          <NavbarBrand tag={Link} to="/">Marine Reports</NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
              </NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav style={{ color: "black" }}>
                    Prices/Users
                </DropdownToggle>
                <DropdownMenu start>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-price-find">Proforma Invoice</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-price-compare">Ports Comparison</NavLink>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-generate">Generated Codes</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-regusers">Users</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-myrequests">My Requests</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-generate">Requests Received</NavLink>
                    </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav style={{ color: "black" }}>
                    Reports
                </DropdownToggle>
                <DropdownMenu start>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-reports">Report Page (Admin)</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-upload">Upload Excel (Mem)</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-import">Raw Data (Mem)</NavLink>
                    </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav autoclose={true} style={{ color: "black" }}>
                    Suppliers
                </DropdownToggle>
                <DropdownMenu start>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-periods">Periods</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-variance">Variances</NavLink>
                    </DropdownItem>
                    <UncontrolledDropdown nav inNavbar direction="end">
                            <DropdownToggle caret nav style={{ color: "black", paddingLeft: "22px" }}>
                        Chevron
                    </DropdownToggle>
                    <DropdownMenu start>
                                <DropdownItem>
                                    <NavLink onClick="alert()" tag={Link} className="text-dark" to="/adm-chev-large">Large Areas</NavLink>
                                    {/* alert will not run, but the page will refresh */}
                                    {/* and this will clear the outside menu          */}
                        </DropdownItem>
                    </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown nav inNavbar direction="end">
                        <DropdownToggle caret nav style={{ color: "black", paddingLeft: "22px" }}>
                            Shell
                        </DropdownToggle>
                        <DropdownMenu start>
                            <DropdownItem>
                                <NavLink onClick="alert()" tag={Link} className="text-dark" to="/adm-shell-large">Large Areas</NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink onClick="alert()" tag={Link} className="text-dark" to="/adm-shellgroupings">Groupings</NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink onClick="alert()" tag={Link} className="text-dark" to="/adm-ipl">IPL</NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink onClick="alert()" tag={Link} className="text-dark" to="/adm-shell-diff">Port Diffs</NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink onClick="alert()" tag={Link} className="text-dark" to="/adm-shell-disc">Discounts</NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown nav inNavbar direction="end">
                        <DropdownToggle caret nav style={{ color: "black", paddingLeft: "22px" }}>
                            Total
                        </DropdownToggle>
                        <DropdownMenu start>
                            <DropdownItem>
                                <NavLink onClick="alert()" tag={Link} className="text-dark" to="/adm-periods">Large Areas</NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink onClick="alert()" tag={Link} className="text-dark" to="/adm-periods">IPL</NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink onClick="alert()" tag={Link} className="text-dark" to="/adm-periods">Port Diffs</NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink onClick="alert()" tag={Link} className="text-dark" to="/adm-periods">Country Diffs</NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink onClick="alert()" tag={Link} className="text-dark" to="/adm-periods">Discounts</NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <DropdownItem>
                       <NavLink tag={Link} className="text-dark" to="/adm-pricelists">Pricelists</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-associations">Prod. Associations</NavLink>
                    </DropdownItem>

                   </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav inNavbar>
                 <DropdownToggle nav style={{ color: "black" }}>
                        Members
                    </DropdownToggle>
                    <DropdownMenu start>
                        <DropdownItem>
                            <NavLink tag={Link} className="text-dark" to="/adm-opsuppliers">Suppliers</NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink tag={Link} className="text-dark" to="/adm-vessels">Vessels</NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink tag={Link} className="text-dark" to="/adm-opports">Ports</NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink tag={Link} className="text-dark" to="/adm-opprodref">Application Points</NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink tag={Link} className="text-dark" to="/adm-itemconversions">Products (Mem)</NavLink>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                            <NavLink tag={Link} className="text-dark" to="/adm-unitbases">Units</NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink tag={Link} className="text-dark" to="/adm-oppackaging">Packaging</NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink tag={Link} className="text-dark" to="/adm-opheaders">Headers (Mem)</NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink tag={Link} className="text-dark" to="/adm-opservices">Services (Mem)</NavLink>
                        </DropdownItem>
                 </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav inNavbar>
                 <DropdownToggle nav style={{ color: "black" }}>
                    System
                 </DropdownToggle>
                 <DropdownMenu start>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-suppliers">Suppliers</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-operators">Members</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-ports">Ports</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-headers">Headers (Sys)</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-servcatsys">Services (Sys)</NavLink>
                    </DropdownItem>


                    <DropdownItem divider />

                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-products">Products</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-prodtypes">Prod. Types</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-prodsyscategories">Prod. Categories (System)</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/adm-prodsupcat">Prod. Categories (Supplier)</NavLink>
                    </DropdownItem>

                </DropdownMenu>
              </UncontrolledDropdown>
              <LoginMenu>
              </LoginMenu>
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
